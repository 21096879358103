import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import partnerDataStore from "../store/PartnerDataStore";
export default async function ActivateService({ customerEmail, productName }: { customerEmail: string; productName: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "partner",
        "process": "activate",
        "accessToken": accessToken,
        "customerEmail": customerEmail,
        "productName": productName
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params,
            errorText: 'Некорректный адрес эл. почты'
        }
    );
    if (!result || !result.data) return;

    // save history
    productName === 'pro'
        ? partnerDataStore.reduceProProductCount()
        : partnerDataStore.reducePremiumProductCount();
    toast(`Пакет ${productName === 'pro' ? '‘Pro’' : 'Premium'} успешно активирован`, { type: "success" });
}