import { useRef } from "react";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import ActivateService from "../service/ActivateService";
import partnerDataStore from "../store/PartnerDataStore";

const ActivateComponent = ({ open, onDismiss, productName }: { open: boolean; onDismiss: any; productName: string }) => {
    // activate
    const emailField = useRef('');
    const onEmailChange = (newValue: string) => { emailField.current = newValue };
    async function execActivate() {
        if (!partnerDataStore.partnerData || (productName === 'pro' && Number(partnerDataStore.partnerData.partner.pro_product_count) < 1) || (productName === 'premium' && Number(partnerDataStore.partnerData.partner.premium_product_count) < 1)) {
            toast(`Нет доступных пакетов ${productName === 'pro' ? '‘Pro’' : '‘Premium’'}`, { type: "error" });
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailField.current || emailField.current.length < 3 || !emailRegex.test(emailField.current)) {
            toast("Неверный адрес эл. почты", { type: "error" });
            return;
        }
        await ActivateService({ customerEmail: emailField.current, productName: productName });
        onDismiss();
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={`Активация пакета ${productName === 'pro' ? '‘Pro’' : '‘Premium’'}`} onSubmit={execActivate}>
            <InputComponent
                initText={emailField.current}
                placeholder="Введите адрес эл. почты"
                handleTextFieldValueChange={onEmailChange}
            />
        </StandartBottomSheetComponent>
    );
}

export default ActivateComponent;