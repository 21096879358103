import { useState } from "react";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import ConfirmBottomSheetComponent from "../../shared/ui/ConfirmModalComponent";
import PurchaseService from "../service/PurchaseService";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import { toast } from "react-toastify";
import { Kit } from "../model/KitsResponse";
import kitsDataStore from "../store/KitsDataStore";
import ScanCodeComponent from "./ScanCodeComponent";
import PurchaseComponent from "./PurchaseComponent";
import BoxIcon from "../../../core/assets/module/card/box.svg";

const MarketComponent = () => {
  // purchase
  const [purchaseState, setPurchaseState] = useState(false);
  const [productAmount, setProductAmount] = useState(0);
  const [productName, setProductName] = useState('');
  function initPurchase() {
    if (productAmount > Number(partnerDataStore.partnerData!.partner.balance))
      toast("Недостаточно средств для покупки", { type: "error" });
    else
      PurchaseService({ kitName: productName });
    setPurchaseState(false);
  }

  // qr code
  const [qrState, setQrState] = useState(false);

  // list
  const [listState, setListState] = useState(false);

  // widget
  const kitItem = (field: Kit, index: number) => {
    return (
      <div key={index}>
        <SharedCardItemComponent
          icon={BoxIcon}
          title={Number(field.price) === 15_000 ? 'Пакет “Partner”' : Number(field.price) === 36_000 ? 'Пакет “Premium”' : Number(field.price) === 360_000 ? 'Пакет “Founder”' : 'Пакет “Prime”'}
          buttonText="Оплатить"
          buttonAction={() => {
            setProductName(Number(field.price) === 15_000 ? 'Пакет “Partner”' : Number(field.price) === 36_000 ? 'Пакет “Premium”' : Number(field.price) === 360_000 ? 'Пакет “Founder”' : 'Пакет “Prime”');
            setProductAmount(Number(field.price));
            setListState(true);
          }}>
          {
            [
              { title: "Программа", value: [{ text: Number(field.price) === 360_000 ? 'Royalty' : "Referral" }] },
              { title: 'Срок действия', value: [{ text: '1 год' }] },
              // { title: !field.bonus ? "Объем (SV)" : "Объем (SV / QV)", value: [{ text: !field.bonus ? field.sv! : `${field.sv}/${field.qv}` }] },
              // { title: "Объем (SV)", value: [{ text: field.sv! }] },
              { title: "Цена (KZT)", value: [{ text: PriceFormatUtil(Number(field.price), false, '') }] },
            ]
          }
        </SharedCardItemComponent>
        {index !== (kitsDataStore.kitsData!.kits.length - 1) && <RetirementComponent size={10} />}
      </div>
    );
  }

  return (
    <>
      {
        !kitsDataStore.kitsData
          ? null
          : <div>
            {
              kitsDataStore.kitsData.kits.map((field, index) => (
                kitItem(field, index)
              ))
            }
          </div>
      }
      <ConfirmBottomSheetComponent
        open={purchaseState}
        onDismiss={() => setPurchaseState(false)}
        onSubmit={initPurchase}
        description={`Вы совершаете оплату “${productName}”. Сумма оплаты будет списана с вашего основного кошелька.`}
        title="Оплата"
      />
      <ScanCodeComponent open={qrState} onDismiss={() => setQrState(false)} />
      <PurchaseComponent title={productName} open={listState} onDismiss={() => setListState(false)} showCode={() => {
        setListState(false);
        setQrState(true);
      }} showPurchase={() => {
        setListState(false);
        setPurchaseState(true);
      }} />
    </>
  );
}

export default MarketComponent;