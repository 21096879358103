import TextComponent from "../../shared/ui/TextComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import AuthorizationService from "../service/AuthorizationService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import GetLinkData from "../../partner/service/GetLinkData";
import { LinkDataResponse } from "../../partner/model/LinkDataResponse";
import Logotype from '../../../core/assets/module/shared/logotype.svg';
import { toast } from "react-toastify";
import ResetPasswordService from "../service/ResetPasswordService";
import InputComponent from "../../shared/ui/InputComponent";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import UrlLauncherUtil from "../../../core/utils/UrlLauncherUtil";
import TemplateConfig from "../../../core/config/TemplateConfig";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import ShowIcon from "../../../core/assets/module/input/show.svg";
import HideIcon from "../../../core/assets/module/input/hide.svg";

const AuthenticateComponent = () => {
  // navigate
  const navigate = useNavigate();

  // obscure
  const [obscure, setObscure] = useState(true);

  // page variables
  const [isAuth, setIsAuth] = useState(true);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isRegistrationFinish, setIsRegistrationFinish] = useState(false);

  // login
  async function emailAuth() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailField.current || emailField.current.length < 3 || !passwordField.current || passwordField.current.length < 3 || !emailRegex.test(emailField.current)) {
      toast("Неверный адрес эл. почты или пароль", { type: "error" });
      return;
    }
    if (!isAuth && (forenameField.current.length < 2 || surnameField.current.length < 2)) {
      toast("Неверное имя или фамилия", { type: "error" });
      return;
    }

    var result = await AuthorizationService({
      email: emailField.current,
      forename: isAuth ? '' : forenameField.current,
      surname: isAuth ? '' : surnameField.current,
      referralLink: searchParams.get('referralLink') ?? '',
      password: passwordField.current,
      navigate: navigate,
      isRegistration: isAuth ? '0' : '1'
    });
    if (!isAuth && result) setIsRegistrationFinish(true);
  }

  // partner referral
  const [searchParams] = useSearchParams();
  const referralLink = searchParams.get('referralLink');
  const [referralNumber, setReferralNumber] = useState('');
  const [referralName, setReferralName] = useState('');
  useEffect(() => {
    async function getReferralNumber() {
      const result = await GetLinkData({ referralLink: referralLink! });
      if (result) {
        setReferralNumber((result.data as LinkDataResponse).referrer.number);
        setReferralName(`${(result.data as LinkDataResponse).referrer.forename} ${(result.data as LinkDataResponse).referrer.surname}`);
        setIsAuth(false);
      }
      return;
    }

    if (referralLink && referralLink.length > 0) getReferralNumber();
  }, [referralLink]);

  // confirm
  const confirm = searchParams.get('confirm');
  useEffect(() => {
    if (confirm && confirm === 'true') toast("Аккаунт успешно активирован", { type: "success" });
  }, [confirm]);

  // email
  const emailField = useRef('');
  const onEmailChange = (newValue: string) => { emailField.current = newValue };

  // password
  const passwordField = useRef('');
  const onPasswordChange = (newValue: string) => { passwordField.current = newValue };

  // forename
  const forenameField = useRef('');
  const onForenameChange = (newValue: string) => { forenameField.current = newValue };

  // surname
  const surnameField = useRef('');
  const onSurnameChange = (newValue: string) => { surnameField.current = newValue };

  // reset password
  async function resetPassword() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailField.current || emailField.current.length < 3 || !emailRegex.test(emailField.current)) {
      toast("Некорректный адрес эл. почты", { type: "error" });
      return;
    }

    var result = await ResetPasswordService({ email: emailField.current });
    if (result) {
      setIsPasswordReset(false);
      setIsAuth(true);
    }
  }

  // widget
  const headerBlock = () => {
    return (
      <div>
        <LinkWrapperComponent onClick={() => UrlLauncherUtil({ url: ApplicationConfig.applicationLink })}>
          <img src={Logotype} alt="project icon" width={100} />
        </LinkWrapperComponent>
        <RetirementComponent size={30} />
        <TextComponent type={!isPasswordReset && isAuth ? 'large' : 'extraLarge'}>
          {
            isPasswordReset
              ? 'Восстановление'
              : isAuth
                ? `Авторизация`
                : 'Регистрация'
          }
        </TextComponent>
        <RetirementComponent size={30} />
        {
          referralNumber.length < 1 || isRegistrationFinish || isAuth
            ? null
            : <div>
              <InputComponent
                disabled={true}
                initText={`Куратор: ${ApplicationConfig.idValue}${referralNumber}`}
                placeholder=""
                handleTextFieldValueChange={null}
                endAdornment={<div style={{ width: 100, textAlign: 'right' }}><TextComponent multiline lines={1}>{referralName}</TextComponent></div>}
              />
              <RetirementComponent size={10} />
            </div>
        }
      </div>
    );
  }
  const authBlock = () => {
    return (
      <div>
        {
          isAuth
            ? null
            : <>
              <InputComponent
                placeholder="Введите имя"
                handleTextFieldValueChange={onForenameChange}
                filter={/[^A-Za-zА-Яа-я]/g}
                maxLength={100}
              />
              <RetirementComponent size={10} />
              <InputComponent
                placeholder="Введите фамилию"
                handleTextFieldValueChange={onSurnameChange}
                filter={/[^A-Za-zА-Яа-я]/g}
                maxLength={100}
              />
              <RetirementComponent size={10} />
            </>
        }
        <InputComponent
          placeholder="Введите адрес эл. почты"
          handleTextFieldValueChange={onEmailChange}
        />
        <RetirementComponent size={10} />
        <InputComponent
          placeholder="Введите пароль"
          handleTextFieldValueChange={onPasswordChange}
          password={obscure}
          endAdornment={
            <LinkWrapperComponent onClick={() => setObscure(!obscure)}>
              <img src={obscure ? ShowIcon : HideIcon} alt="password icon" />
            </LinkWrapperComponent>
          }
        />
        <RetirementComponent />
        <RaisedButtonComponent
          placeholder={isAuth ? "Войти" : "Зарегистрироваться"}
          onClick={() => emailAuth()}
        />
      </div>
    );
  }
  const resetBlock = () => {
    return (
      <div>
        <InputComponent
          placeholder="Введите адрес эл. почты"
          handleTextFieldValueChange={onEmailChange}
        />
        <RetirementComponent />
        <RaisedButtonComponent
          placeholder="Отправить"
          onClick={() => resetPassword()}
        />
      </div>
    );
  }
  const nagivationBlock = () => {
    const resetItem = <TextComponent weight={TemplateConfig.lightweightText}>
      Забыли пароль?&nbsp;
      <LinkWrapperComponent onClick={() => setIsPasswordReset(true)} underline>
        Восстановить
      </LinkWrapperComponent>
    </TextComponent>;
    const backItem = <TextComponent weight={TemplateConfig.lightweightText}>
      Я вспомнил(а) пароль.&nbsp;
      <LinkWrapperComponent onClick={() => setIsPasswordReset(false)} underline>
        Войти
      </LinkWrapperComponent>
    </TextComponent>;
    const loginItem = <TextComponent weight={TemplateConfig.lightweightText}>
      У меня есть аккаунт.&nbsp;
      <LinkWrapperComponent onClick={() => setIsAuth(true)} underline>
        Войти
      </LinkWrapperComponent>
    </TextComponent>;
    const rulesItem = <TextComponent weight={TemplateConfig.lightweightText}>
      Регистрируясь, Вы соглашаетесь с&nbsp;
      <LinkWrapperComponent underline onClick={() => UrlLauncherUtil({ url: `${ApplicationConfig.agreementLink}/agreement.pdf`, newTab: true })}>
        условиями оферты
      </LinkWrapperComponent>,&nbsp;
      <LinkWrapperComponent underline onClick={() => UrlLauncherUtil({ url: `${ApplicationConfig.agreementLink}/privacy.pdf`, newTab: true })}>
        политикой конфиденциальности
      </LinkWrapperComponent>.
    </TextComponent>;

    return (
      <div>
        <RetirementComponent />
        {
          isPasswordReset
            ? backItem
            : isAuth
              ? resetItem
              : <>
                {rulesItem}
                <RetirementComponent size={10} />
                {loginItem}
              </>
        }
      </div>
    );
  }

  return (
    <div style={{ textAlign: 'center', paddingTop: 26, paddingBottom: 26 }}>
      {headerBlock()}
      {
        isRegistrationFinish
          ? <TextComponent weight={TemplateConfig.regularText}>
            Благодарим вас за выбор {ApplicationConfig.applicationName}. Для продолжения необходимо подтвердить аккаунт. Инструкции отправлены на вашу эл. почту.
          </TextComponent>
          : <>
            {isPasswordReset ? resetBlock() : authBlock()}
            {nagivationBlock()}
          </>
      }
    </div>
  );
}

export default AuthenticateComponent;