import { observer } from "mobx-react-lite";
import { useState } from "react";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import partnerDataStore from "../store/PartnerDataStore";
import ActivateComponent from "./ActivateComponent";
import ActivationListComponent from "./ActivationListComponent";
import CheckIcon from "../../../core/assets/module/card/check.svg";

const OrderComponent = () => {
    // init
    const partner = partnerDataStore.partnerData!.partner;

    // activate
    const [activateWindow, setActivateWindow] = useState(false);
    const [productName, setProductName] = useState('');

    // list
    const [listWindow, setListWindow] = useState(false);

    return (
        <>
            <SharedCardItemComponent
                icon={CheckIcon}
                title="Пакет “Partner”"
                buttonText="Активировать пакет"
                buttonAction={() => {
                    setProductName('pro');
                    setActivateWindow(true);
                }}>
                {
                    [
                        { title: 'Программа', value: [{ text: 'Referral' }] },
                        { title: 'Количество', value: [{ text: partner.pro_product_count }] },
                        {
                            title: 'Активированные ', value: [{
                                text: 'Показать', onClick: () => {
                                    setProductName('pro');
                                    setListWindow(true);
                                }
                            }]
                        }
                    ]
                }
            </SharedCardItemComponent>
            <RetirementComponent size={10} />
            <SharedCardItemComponent
                icon={CheckIcon}
                title="Пакет “Premium”"
                buttonText="Активировать пакет"
                buttonAction={() => {
                    setProductName('premium');
                    setActivateWindow(true);
                }}>
                {
                    [
                        { title: 'Программа', value: [{ text: 'Referral' }] },
                        { title: 'Количество', value: [{ text: partner.premium_product_count }] },
                        {
                            title: 'Активированные ', value: [{
                                text: 'Показать', onClick: () => {
                                    setProductName('premium');
                                    setListWindow(true);
                                }
                            }]
                        }
                    ]
                }
            </SharedCardItemComponent>
            <ActivateComponent open={activateWindow} onDismiss={() => setActivateWindow(false)} productName={productName} />
            <ActivationListComponent open={listWindow} onDismiss={() => setListWindow(false)} productName={productName} />
        </>
    );
}

export default observer(OrderComponent);